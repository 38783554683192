
const src =
"https://assets.mixkit.co/videos/preview/mixkit-tree-with-yellow-flowers-1173-large.mp4";

const Video = () => {
return (
  <video controls width="100%">
    <source src={src} type="video/mp4" />
    Sorry, your browser doesn't support embedded videos.
  </video>
);
};

export default Video;
